/* istanbul ignore file */

import { graphql } from "gatsby";
import React from "react";
import { ProductsGridDataFragment } from "../../../graphql-types";
import GatsbyImage from "../GatsbyImage";
import { RichTextRenderer } from "../RichTextRenderer";

type Props = ProductsGridDataFragment;

export default function ProductsGrid({ productCards }: Props) {
  return (
    <div className="md:max-w-4xl md:mx-auto px-4 relative">
      <ul className="flex flex-wrap gap-8 items-stretch justify-center">
        {productCards.map(({ title, description, image }, index) => {
          return (
            <li
              className="sm:basis-[calc(50%-3rem)] rounded-lg overflow-hidden shadow border border-b-[3px] border-secondary-500 bg-white"
              key={index + title}
            >
              <GatsbyImage
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                alt={image.description!}
                // className="w-full"
                image={image.gatsbyImageData}
              />
              <div className="mt-4 p-4">
                <h3 className="text-primary-500 font-heading font-extrabold leading-none tracking-tight text-2xl">
                  {title}
                </h3>
                <RichTextRenderer
                  className="inline-html mt-4"
                  component="div"
                  raw={description.raw}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export const fragment = graphql`
  fragment ProductsGridData on ProductsGrid {
    productCards {
      id
      title
      description {
        raw
      }
      image {
        description
        gatsbyImageData(width: 480, height: 480)
      }
    }
  }
`;
